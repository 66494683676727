import './components/jquery.responsifyBgImg';
import './components/checkflexgap';
import './vendor/owl.carousel.min';
import './vendor/lity.min';

(function($) {

    const $body = $('body');
    let searchDisplay;
	  // Offcanvas

    $('.js-toggle-nav').on('click', () => {
      $body.toggleClass('menu-open');
    });

    // Generic Toggles

    $('.js-toggle').on('click',function() {
        let $this = $(this); 
        let target = $this.data('target');
        let type = $this.data('toggle');

        if(!type) {
            if (target == 'self') {
                $this.toggleClass('active');
            } else {
                let $target = $(target);
                $target.toggleClass('active');
            }
        } else {
            if (type == 'faq') {
                let $target = $(target);
                $this.parent($target).toggleClass('is-visible');
            }
        }
    });

    // Search toggle

    $('body').on('click', '.js-search', function(e) {
        e.preventDefault();

        if (searchDisplay == true) {
            hideSearch();
        } else {
            displaySearch();
        }
    });

    function displaySearch() {
        $body.addClass('search-open');
        $('.site-header__search-field').focus();
        searchDisplay = true;
    }

    function hideSearch() {
        $body.removeClass('search-open');
        searchDisplay = false;
    }

    $('.js-submenu').on('click', function() {
        var $this = $(this);

        $this.parent().toggleClass('submenu-open');
    });

    var isInViewport = function(elem) {
        var distance = elem.getBoundingClientRect();
        return (
          distance.top >= 0 &&
          distance.left >= 0 &&
          distance.bottom <= (window.innerHeight+100 || document.documentElement.clientHeight) &&
          distance.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
      
    var findMe = document.querySelectorAll('.js-visible');
      
    window.addEventListener('scroll', function(event) {
      // add event on scroll
        findMe.forEach(element => {
          //for each .thisisatest
            if (isInViewport(element)) {
            //if in Viewport
                element.classList.add("is-visible");
            }
        });
    }, false);

    $(window).on('load', function() {
        findMe.forEach(element => {
            //for each .thisisatest
              if (isInViewport(element)) {
              //if in Viewport
                  element.classList.add("is-visible");
              }
          });
    });

    // Sticky Header

    const $header = $('.site-header');
    var position = $(document).scrollTop();
    var fixed = false;
    var mouseover = false;
    const winW = $(window).width();

    if (position >= 1 && winW > 1024) {
        $body.addClass('nav-fixed');
        $header.addClass('is-fixed');
        $header.addClass('is-visible');
        fixed = true;
    }

    if (winW > 1024) {
        $header.hover(function(){
            $(this).addClass('is-visible');
            $(this).removeClass('is-hidden');
            mouseover = true;
        }, function(){
            $(this).removeClass('is-visible');
            mouseover = false;
        });
        
        $(document).on('scroll', function(){
            var scroll = $(document).scrollTop();   

            if(scroll > position) {
                if (position >= 1) {
                    $body.addClass('nav-fixed');
                    $header.addClass('is-fixed');
                    fixed = true;
                }

                if (position >= 110) {
                    $header.removeClass('is-visible');
                }
                
            } else {
                if (position < 1) {
                    $body.removeClass('nav-fixed');
                    $header.removeClass('is-fixed');
                    $header.removeClass('is-hidden');

                    if (!mouseover) {
                        $header.removeClass('is-visible');
                    }
                    fixed = false;
                } else {
                    if (fixed === true) {
                        $header.removeClass('is-hidden');
                        $header.addClass('is-visible');
                    }
                }
            }   

            setTimeout(function() { 
                if (position < 1) {
                    $body.removeClass('nav-fixed');
                    $header.removeClass('is-fixed');
                    $header.removeClass('is-hidden');
                    if (!mouseover) {
                        $header.removeClass('is-visible');
                    }
                    fixed = false;
                }
            }, 200);    

            position = scroll;
            
        });
    }
    

    // Owl Carousel

    const owlHeroSlider = $('.owl-hero-slider');

    owlHeroSlider.owlCarousel({
        loop:true,
        margin:0,
        nav:false,
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        animateOut: 'fadeOut',
        items:1
    });

    const owlCategoryPanels = $('.owl-panel-carousel');

    owlCategoryPanels.owlCarousel({
        loop:false,
        nav:false,
        dots: true,
        autoplay: false,
        smartSpeed: 750,
        responsive:{
            0:{
                items:1,
                margin:20
            },
            768:{
                items:2,
                margin:20
            },
            960: {
                items:3,
                margin:36
            }
        }
    });

    const owlCategoryCarousel = $('.owl-category-carousel');

    owlCategoryCarousel.owlCarousel({
        loop:true,
        center: true,
        nav:true,
        dots: false,
        autoplay: false,
        smartSpeed: 750,
        responsive:{
            0:{
                items:1
            },
            480:{
                items:2,
                margin:20
            },
            960: {
                items:3,
                margin:20
            },
            1200: {
                items:4,
                margin:35
            },
            1400: {
                items:5,
                margin:35
            }
        }
    });

    $('.js-custom-owl-control').on('click',function () {
        var $this = $(this);
        var slider = $($this.data('slider'));
        changeSlide($this, slider);

    });

    function changeSlide(el, owl) {
        
        var dir = el.data('direction');
        owl.trigger(dir +'.owl.carousel', [300]);

    }

    // Scroll to ID

    $('.js-scrollto').on('click', function () {
        var id = $(this).data('target');
        var $target = $(id);

        if($target.hasClass('dropdown-list__item')) { 
            $target.find('.dropdown-list__section-title').trigger('click');
        }

        scrollTo(id);
    });

    function scrollTo(id){
        var el;
        var scrollPos;
        var winW = $(window).width();

        if (id === 'top') {
            scrollPos = 0;
        } else {
            el = $(id);
            scrollPos = el.offset().top-250;
        }

        $('html,body').animate({scrollTop: scrollPos},'slow');
    }

    // Dropdowns

    $('.dropdown-list__section').hide();
    
    $('.dropdown-list__section-title').on('click', function() {
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active').find('.dropdown-list__section').slideUp();
        } else {
            $('.dropdown-list > li.active .dropdown-list__section').slideUp();
            $('.dropdown-list > li.active').removeClass('active');
            $(this).parent().addClass('active').find('.dropdown-list__section').slideDown();
        }
        return false;
    });

    // Responsive Background Images

    $('.js-responsive-bg').responsifybgimg();

	// Footcols

    $('.site-footer__column__heading').on('click', function () {
        $(this).parent('.site-footer__column').toggleClass('open');
    });

    // Tabs

    $('.js-tab').on('click', function() {
        var $this = $(this);
        if (!$this.hasClass('tabbed-panels__tab--active')) {
            $('.tabbed-panels__tab--active').removeClass('tabbed-panels__tab--active');
            $('.tabbed-panels__item--active').removeClass('tabbed-panels__item--active is-visible');
            var target = $this.data('target');
            var tab_index = $this.data('tab-index');

            if(!tab_index && tab_index != 0) {
                $this.addClass('tabbed-panels__tab--active');
            } else {
                var i = tab_index;
                $('.tabbed-panels__tab').eq(i).addClass('tabbed-panels__tab--active');
            }
            
            changePanel(target);
        }
    });



    function changePanel(panel) {
        
        $(panel).addClass('tabbed-panels__item--active');
        
        setTimeout(function() {
            $(panel).addClass('is-visible');
        },200);
    }

})(jQuery);