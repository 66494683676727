/*
 * jQuery Responsify Background Images v0.1
 * Copyright 2017 Underdog Design
 * Contributing Author: Kim Ruddock
 */

(function($){
    $.fn.responsifybgimg = function() {
        var methods = {};
        var el = this;

        methods = {
            init: function() {
                var winWidth = $(window).width();
                var small = 599;
                var large = 1280;

                el.each(function(i) {
                    if( winWidth <= small ) {
                        el.eq(i).css( 'background-image', 'url(' + el.eq(i).data('image-small') + ')' );
                    } else if (winWidth > small &&  winWidth <= large) {
                        el.eq(i).css( 'background-image', 'url(' + el.eq(i).data('image-medium') + ')' );
                    } else if (winWidth > large) {
                        el.eq(i).css( 'background-image', 'url(' + el.eq(i).data('image-large') + ')' );
                    }
                });
            },
            resize: function() {
                var bgImageScrollTimer;
                    
                if (bgImageScrollTimer) { 
                    clearTimeout(bgImageScrollTimer); 
                }

                bgImageScrollTimer = setTimeout(function() {
                    methods.init();
                }, 100);
            }
        }

        $(window).on('resize', methods.resize);

        return methods.init();
    }
})(jQuery);